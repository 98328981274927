<template>
  <CustomDialog
    :extra-body-class="`pa-1`"
    :open.sync="open"
    :title="title"
    @click-close="close_dialog"
    :max-width="mdAndUp ? 1366 : `100%`"
  >
    <template v-slot:content>
      <v-card flat v-if="item" min-height="500">
        <v-card-text class="pa-0">
          <v-stepper
            class="product-view-dialog mb-0"
            flat
            style="box-shadow: none"
            v-model="stepper"
          >
            <v-stepper-header
              class="hidden"
              flat
              style="display: none; height: 0px"
            >
              <v-stepper-step
                :complete="stepper > 1"
                :rules="[stepOneRules]"
                step="1"
              >
                Customize Order
              </v-stepper-step>
              <v-divider />
              <v-stepper-step step="2" :rules="[stepTwoRules]">
                Submit Order
              </v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
              <!--Step 1 Selecting templates and fillup common form-->
              <v-stepper-content class="pa-1" step="1">
                <v-card flat>
                  <v-card-text class="border-1 border-color--grey rounded-10">
                    <v-row dense align="stretch" justify="center">
                      <v-col
                        :cols="mdAndUp ? 8 : 12"
                        class="d-flex align-center"
                        style="min-height: 300px"
                      >
                        <div
                          v-if="selected"
                          class="
                            fullwidth
                            grey
                            pa-2
                            border-1
                            d-flex
                            flex-column
                            align-center
                            border-color--grey
                            rounded-10
                          "
                        >
                          <Attachment
                            animated
                            :item="selected"
                            :classes="`mx-auto`"
                            :styles="`max-height:100%;height:400px;max-width:100%;`"
                            v-if="selected"
                            :key="selected.id"
                          ></Attachment>
                          <v-slide-group class="mt-3" multiple show-arrows>
                            <v-slide-item
                              v-for="(preview, key) in item.previews"
                              :key="preview.id"
                              v-slot:default="{ active, toggle }"
                            >
                              <v-card
                                flat
                                class="mx-2"
                                max-width="120"
                                @click.stop="selected = preview"
                              >
                                <v-card-title
                                  class="title-card"
                                  v-if="!is_product_owner"
                                >
                                  <v-spacer />
                                  <v-btn
                                    class="ml-1 primary"
                                    depressed
                                    small
                                    fab
                                    icon
                                    @click.stop="
                                      toggleCb(toggle, preview, key, active)
                                    "
                                  >
                                    <v-icon
                                      :color="
                                        preview.selected ? `success` : '#000000'
                                      "
                                    >
                                      {{
                                        preview.selected
                                          ? "mdi-sticker-check"
                                          : "mdi-sticker-outline"
                                      }}
                                    </v-icon>
                                  </v-btn>
                                </v-card-title>
                                <Attachment
                                  :item="preview"
                                  prefer-thumb
                                  :classes="`ma-auto`"
                                />
                              </v-card>
                            </v-slide-item>
                          </v-slide-group>
                        </div>
                      </v-col>
                      <v-col :cols="mdAndUp ? 4 : 12" class="pa-1">
                        <FormInput
                          :form-disabled="is_product_owner"
                          :value="forms"
                          @input="answered_form = $event"
                        >
                          <template v-slot:prepend>
                            <v-col md="12">
                              <p class="fs-18 primary--text fw-700 text-center">
                                {{ item.title | ucwords }}
                              </p>
                              <v-chip
                                class="
                                  my-1
                                  fullwidth
                                  d-flex
                                  justify-center
                                  align-center
                                "
                                large
                                pill
                                v-if="item.sell_price !== '0.00'"
                              >
                                <span class="ml-1 fs-17 fw-700 primary--text">
                                  Purchase for
                                </span>
                                <span class="ml-1 fs-20 fw-900 primary--text">
                                  {{ item.sell_price | money }}
                                </span>
                              </v-chip>
                              <p class="subtitle-1" v-if="item.description">
                                {{ item.description }}
                              </p>
                            </v-col>
                          </template>
                          <template v-slot:append>
                            <span v-if="forms.length" class="caption">
                              * Required
                            </span>
                          </template>
                        </FormInput>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-stepper-content>

              <!--Step 2 Fillup individual form if form type is unique-->
              <v-stepper-content class="pa-1" step="2">
                <v-card flat>
                  <v-card-text>
                    <v-row
                      class="fill-height wrap"
                      align="center"
                      dense
                      justify="center"
                    >
                      <v-col cols="12" class="d-flex py-1 mb-2">
                        <span class="fw-700 fs-18 primary--text pa-1">
                          Selected Templates
                        </span>
                        <v-chip class="ml-2" color="primary" dark small>
                          {{ selected_count }}
                        </v-chip>
                        <v-spacer />
                        <span class="subtitle-2 pa-1 error--text">
                          * Required
                        </span>
                      </v-col>
                      <v-col cols="12">
                        <v-row
                          align="center"
                          justify="center"
                          v-for="(selected, index) in item.previews"
                          :key="selected.id"
                          v-show="selected.selected"
                          class="border-1 mb-1 rounded-10 border-color--grey"
                        >
                          <v-col 
                            cols="6"
                            class="d-flex align-center justify-center"
                          >
                            <Attachment
                              :item="selected"
                              prefer-thumb
                              :styles="`max-width:300px;`"
                              :classes="`ma-auto`"
                            ></Attachment>
                          </v-col>
                          <v-divider vertical />
                          <v-col>
                            <FormInput
                              :form-disabled="is_product_owner"
                              :value="product.previews[index].form"
                              @input="selected.form = $event"
                            >
                            </FormInput>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card-text>
      </v-card>
    </template>
    <template v-slot:entire-actions>
      <v-btn
        :disabled="btnloading"
        @click="stepper = 1"
        class="ml-1 text-none px-5"
        color="primary"
        depressed
        v-if="can_buy && stepper === 2"
      >
        <v-icon left>mdi-step-backward </v-icon>
        Back
      </v-btn>
      <v-spacer />
      <v-btn
        @click="add_to_likes"
        class="ml-1 text-none px-5"
        color="primary"
        depressed
      >
        <v-icon left>mdi-cards-heart </v-icon>
        {{ like_label | ucwords }}
      </v-btn>
      <v-btn
        :disabled="!can_next || btnloading"
        @click="stepper = 2"
        class="ml-1 text-none px-5"
        color="primary"
        depressed
        v-if="can_buy && stepper === 1"
      >
        Next
        <v-icon right>mdi-step-forward </v-icon>
      </v-btn>

      <v-btn
        :loading="btnloading"
        @click="submit"
        :disabled="!stepTwoRules()"
        class="ml-1 text-none px-5"
        color="primary"
        depressed
        v-if="can_buy && stepper === 2"
      >
        <v-icon left>mdi-cart-plus </v-icon>
        Confirm & Submit Order
      </v-btn>
      <v-btn
        @click="editProduct(product)"
        class="ml-1 text-none"
        text
        v-if="is_product_owner"
      >
        <v-icon left>mdi-circle-edit-outline </v-icon>
        Edit Template
      </v-btn>
    </template>
  </CustomDialog>
</template>

<script>
import CustomDialog from "@/common/BaseComponents/CustomDialog/CustomDialog.vue";
import FormInput from "@/modules/ProductBuilder/components/FormInput.vue";
import request from "@/services/axios_instance";
import _cloneDeep from "lodash/cloneDeep";
import { mapGetters } from "vuex";
import SelectTemplate from "./SelectTemplate.vue";
import { offers_mixin } from "../mixin";
import FormTableDisplay from "./FormTableDisplay.vue";

export default {
  name: "ProductViewDialog",
  components: {
    FormTableDisplay,
    SelectTemplate,
    FormInput,
    CustomDialog,
  },
  mixins: [offers_mixin],
  props: {
    value: { type: Boolean, default: false },
    product: Object,
  },
  data: () => ({
    open: false,
    item: null,
    stepper: 1,
    selected: null,
    selected_templates_with_form: [],
    answered_form: [],
    btnloading: false,
  }),
  watch: {
    value(val) {
      this.open = val;
    },
    open(val) {
      this.$emit("input", val);
    },
    product: {
      handler: function (val) {
        this.item = _cloneDeep(val);
        this.$nextTick(() => {
          if (val && val.previews.length > 0) {
            this.selected = this.item.previews[0];
          } else {
            this.selected = null;
          }
        });
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    ...mapGetters(["user"]),
    is_product_owner() {
      return this.item && this.user.id === this.item.user_id;
    },
    title() {
      return this.item ? this.item.title : "";
    },
    images() {
      return this.item ? this.item.previews : [];
    },
    forms() {
      return this.item ? this.item.form : [];
    },
    like_label() {
      return this.item && this.item.is_liked ? "Unlike" : "Like";
    },
    can_buy() {
      return !!(this.item && this.item.user_id !== this.user.id);
    },
    selected_templates: {
      get() {
        return this.item.previews.filter((item) => {
          return item.selected === true;
        });
      },
      set(val) {
        //
      },
    },
    selected_count() {
      return this.selected_templates.length;
    },
    can_next() {
      return this.stepOneRules();
    },
  },
  methods: {
    getForm(attachment) {
      if (!attachment.hasOwnProperty("form")) return [];
      return _cloneDeep(attachment.form);
    },
    close_dialog() {
      this.open = false;
      this.stepper = 1;
    },
    add_to_likes() {
      request.post(`api/likes/${this.item.id}`).then(({ data }) => {
        this.item.is_liked = data.is_liked;
        this.item.likes_count = data.likes_count;
        this.$emit("item-liked", this.item);
        this.appSnackbar( data.message);
      });
    },
    getPayload() {
      return {
        form: this.answered_form,
        product_id: this.item.id,
        selected_templates: this.selected_templates.map((i) => {
          return { id: i.id, form: i.form };
        }),
      };
    },
    stepOneRules() {
      if (this.selected_count < 1) {
        return false;
      }
      //validate common forms
      if (this.item && this.forms && this.answered_form.length > 0) {
        for (
          let i = 0;
          i < this.forms.length && this.answered_form.length;
          i++
        ) {
          if (
            this.answered_form[i].hasOwnProperty("required") &&
            this.answered_form[i].required &&
            (!this.answered_form[i].value ||
              this.answered_form[i].value.length === 0)
          ) {
            return false;
          }
        }
      }
      return true;
    },
    stepTwoRules() {
      if (!this.stepOneRules()) {
        return false;
      }
      //validate individual image form
      if (this.item && this.selected_templates.length > 0) {
        for (let j = 0; j < this.selected_templates.length; j++) {
          let form = this.selected_templates[j].form;
          for (let i = 0; i < form.length && form.length; i++) {
            if (
              form[i].hasOwnProperty("required") &&
              form[i].required &&
              (!form[i].value || form[i].value.length === 0)
            ) {
              return false;
            }
          }
        }
      }
      return true;
    },
    is_image(mimeType) {
      return !!(typeof mimeType !== "undefined" && mimeType.includes("image"));
    },
    get_extension(filename) {
      if (!filename) return "rar";
      let split = filename.split(".");
      return split[split.length - 1].toLowerCase();
    },
    submit() {
      this.btnloading = true;
      request
        .post(`api/orders`, this.getPayload())
        .then(({ data }) => {
          this.open = false;
          this.appSnackbar( "Order submitted!");
          this.reset();
        })
        .finally(() => (this.btnloading = false));
    },
    reset() {
      this.stepper = 1;
      this.item = null;
      this.answered_form = [];
    },
    toggleCb(toggle, preview, key, active) {
      this.selected = preview;
      this.item.previews[key].selected = active;
      toggle();
    },
  },
};
</script>
<style scoped lang="scss">
.title-card {
  position: absolute;
  top: 0px;
  width: 100%;
  background: transparent;
}
</style>